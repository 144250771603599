import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import CustomLink from "../../components/link"
import perfiles from "../../../static/img/irstrat/perfiles.png"
import saul from "../../../static/img/irstrat/saul.png"
import rodrigo from "../../../static/img/irstrat/rodrigo.png"
import joven_exitoso from "../../../static/img/irstrat/joven_exitoso.jpeg"
import clientes from "../../../static/img/irstrat/clientes-1.png"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Clients"/>
            <Banner title="Clients"/>
            <section className="executive_area perfil">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex align-items-center">
                            <div className="ex_content">
                                <h2>30 public companies on 3 continents</h2>
                                <span className="sec_border"></span>
                                <p>
                                    Our clients perceive the value of a close relationship with the markets, the
                                    implementation of a solid
                                    corporate governance, a timely intelligence, and a perfectly structured and clear
                                    narrative that
                                    accurately reflects their fundamentals and business plan; therefore, they seek to
                                    gain competitive
                                    edges and positioning out of them.
                                </p>
                                <p>Most of our clients have approached us on the recommendation of other clients, as
                                    well as due our
                                    prestige. The value and edge of our extensive network of institutional contacts in
                                    Mexico and the US,
                                    the specialized knowledge of our team, and our broad portfolio of services have been
                                    recognized by our
                                    clients as the best option to achieve the results they pursue.</p>
                                <p>Thanks to our consistent delivery of value and proactive approach, our clients
                                    continue to expand
                                    their business relations with us, recommending us to new clients from their business
                                    circle at the
                                    same time; making IRStrat the fastest growing Mexican consulting firm in the
                                    industry.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0.1s" data-wow-duration="1.0s">
                            <img src={perfiles} alt="Profiles"/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-5 pb-5 porque">

            </section>

            <section className="position-relative">
            <div className="container">
                <div className="section_title black_c ">
                    <h2 className="mt-4">Testimonies</h2>
                    <span className="sec_border"/>
                </div>
            </div>
                <div className="container">
                    <div id="testimonial" className="testimonial_slider owl-carousel testimonial_porque">
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                               <img src={saul} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left"><em>"Thanks to IRStrat’s solutions, we have been able to improve the quality of our message and expand our visibility to a
                                            wider range of local and foreign international investors, deepen our relationship with major local financial institutions, and enhance shareholder and
                                            investor services, through the refinement of our IR materials. Over the years, we have worked with other IR agencies; thus, we have a clear reference
                                            framework, and we can undoubtedly state that the solutions provided by IRStrat are in line with the highest international standards, with extremely
                                            fast response times and great impact on value creation."</em></p>
                                            <p className="text-right"><small><b>Saúl Castañeda<br></br>
                                            CFO in Grupo Industrial Saltillo (GIS)</b></small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                               <img src={rodrigo} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left"><em>"In Maxcom, we are very pleased with the suite of services provided by IRStrat; they have significantly improved
                                            our delivery of message. In the particular case of our IR website, they carried out its development from A to Z (content, graphical identity
                                              and technological platform), in record time, exceeding our expectations of quality and service. We received multiple positive feedback from
                                              our investors for the excellent quality of our new IR website; i sincerely believe that IRStrat is the best choice in investor relations
                                              consulting for the Mexican market."</em></p>
                                            <p className="text-right"><small><b>Rodrigo Wright<br></br>
                                             ‎Financial Business Planner, Treasury & IR Officer in Maxcom Telecomunicaciones</b></small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row service_info">
                                <div className="col-12 mb-5">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-md-5 text-center order-0 order-md-1 mb-3 mb-md-0">
                                            <div className="img_hover">
                                               <img src={joven_exitoso} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 order-1 order-md-0">
                                            <p className="text-left"><em>"IRStrat provided invaluable support for the development and
                                            implementation of our insider trading policy, allowing us to improve our corporate governance practices.
                                            IRStrat satisfactorily met our expectations in terms of both time and quality of service."</em></p>
                                            <p className="text-right"><small><b>Jonathan Rangel<br></br>
                                             Investor Relations Officer in Crédito Real</b></small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="about_consulting_area section_gap bg-white">
                <div className="container">
                        <div className="row strategy_info d-flex align-items-center">
                         <div className="col-lg-5">
                            <img className="video_img wow fadeIn" src={clientes} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h2>Request for information</h2>
                                <span className="sec_border"></span>

                                <p>Our team has been involved in the entire spectrum of solutions for investor
                                    relations, strategic communications, PR and financial lobbying, debt and equity
                                    markets, and investor prospecting.</p>
                                <p>For a complete list of our clients and references, and to conduct a diagnosis of your
                                    investor relations program, issuance capacity and compliance with best practices,
                                    please contact us, our team will be more than pleased to assist you.</p>
                                <CustomLink to="/contacto" className="get_btn btn_hover hover_color">Contact
                                    us</CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
